import gsap from "gsap";
import SplitType from "split-type";

document.addEventListener("DOMContentLoaded", function () {
  const introduction = document.getElementById("introduction");

  if (introduction) {
    setTimeout(() => {
      introduction.style.opacity = 0.75;
      introduction.style.transform = "translate(0, -100%)"; // Corrected the property name
    }, 1000);
  }
});

jQuery(document).ready(function ($) {
  const apartmentsItems = document.querySelectorAll(".apartments_item");

  apartmentsItems?.forEach((item) => {
    const playVideo = () => {
      const video = item.querySelector("video");

      if (video) {
        video.play();
      }
    };

    const pauseVideo = () => {
      const video = item.querySelector("video");

      if (video) {
        video.pause();
      }
    };

    pauseVideo();

    item.addEventListener("mouseover", playVideo);
    item.addEventListener("mouseleave", pauseVideo);
  });

  const roundedButton = document.getElementById("roundedButton");
  const readMoreButton = document.querySelector(".roundedButton.readMore");
  const readMoreContentParagraphs = document.querySelectorAll(
    ".read-more-content p"
  );

  const readLessText = document.querySelector(".readLessText");
  const readMoreText = document.querySelector(".readMoreText");

  readMoreContentParagraphs?.forEach((paragraph, index) => {
    if (index !== 0 && index !== 1) {
      new SplitType(paragraph, { type: "lines" });

      const paragraphLines = paragraph.querySelectorAll(".line");

      paragraphLines?.forEach((line) => {
        paragraph.classList.add("shortened");
        line.style.maxHeight = 0;
        line.style.opacity = 0;
        line.style.transform = "translate(0, 100px)"; // Corrected the property name
      });
    }
  });

  readMoreButton?.addEventListener("click", function () {
    readMoreContentParagraphs?.forEach((paragraph, index) => {
      if (index !== 0 && index !== 1) {
        const paragraphLines = paragraph.querySelectorAll(".line");
        if (paragraph.classList.contains("shortened")) {
          // paragraph.style.maxHeight = null; // Reset maxHeight to let the content expand
          setTimeout(() => {
            if (paragraphLines) {
              gsap.fromTo(
                paragraphLines,
                {
                  y: 100,
                  opacity: 0,
                  maxHeight: 0,
                },
                {
                  y: 0,
                  opacity: 1,
                  maxHeight: 26,
                  stagger: 0.15,
                  duration: 1,
                  ease: "power4.out",
                }
              );
            }
            paragraph.classList.remove("shortened");
          }, (index - 2) * 1000);
          roundedButton.style.pointerEvents = "none";
          readLessText.style.display = "block";
          readMoreText.style.display = "none";

          setTimeout(() => {
            roundedButton.style.pointerEvents = "all";
          }, (readMoreContentParagraphs.length - 2) * 850);
        } else {
          const reversedLines = Array.from(paragraphLines).reverse();
          setTimeout(() => {
            if (reversedLines) {
              gsap.fromTo(
                reversedLines,
                {
                  y: 0,
                  opacity: 1,
                  maxHeight: 26,
                },
                {
                  y: 50,
                  opacity: 0,
                  maxHeight: 0,
                  stagger: 0.15,
                  duration: 1,
                  ease: "power4.out",
                }
              );
            }
            paragraph.classList.add("shortened");
          }, (readMoreContentParagraphs.length - index - 1) * 1000);
          readLessText.style.display = "none";
          readMoreText.style.display = "block";
          roundedButton.style.pointerEvents = "none";

          setTimeout(() => {
            roundedButton.style.pointerEvents = "all";
          }, (readMoreContentParagraphs.length - 2) * 800);
        }
      }
    });
  });

  const circle = document.querySelector(".roundedButton .circle");

  let timeline = gsap.timeline({ paused: true });
  if (timeline && circle) {
    timeline
      .to(
        circle,
        { top: "-25%", width: "150%", duration: 0.4, ease: "power3.in" },
        "enter"
      )
      .to(circle, { top: "-150%", width: "125%", duration: 0.25 }, "exit");
  }

  let timeoutId = null;

  const circleApartments = document.querySelector(".contact-fixed .circle");

  let timelineApartments = gsap.timeline({ paused: true });
  if (timelineApartments && circleApartments) {
    timelineApartments
      .to(
        circleApartments,
        { top: "-25%", width: "150%", duration: 0.4, ease: "power3.in" },
        "enter"
      )
      .to(
        circleApartments,
        { top: "-150%", width: "125%", duration: 0.25 },
        "exit"
      );
  }

  let timeoutIdApartments = null;

  function manageMouseEnter() {
    if (timeoutId) clearTimeout(timeoutId);
    timeline.tweenFromTo("enter", "exit");
  }

  function manageMouseLeave() {
    timeoutId = setTimeout(function () {
      timeline.play();
    }, 300);
  }

  function manageMouseEnterApartments() {
    if (timeoutIdApartments) clearTimeout(timeoutIdApartments);
    timelineApartments.tweenFromTo("enter", "exit");
  }

  function manageMouseLeaveApartments() {
    timeoutIdApartments = setTimeout(function () {
      timelineApartments.play();
    }, 300);
  }

  const apartmentsButton = document.querySelector(".contact-fixed");

  apartmentsButton?.addEventListener("mouseenter", manageMouseEnterApartments);
  apartmentsButton?.addEventListener("mouseleave", manageMouseLeaveApartments);

  roundedButton?.addEventListener("mouseenter", manageMouseEnter);
  roundedButton?.addEventListener("mouseleave", manageMouseLeave);

  const heading = new SplitType(".landing h1", { type: "lines" });

  const description = new SplitType(".description p", { types: "lines" });

  (function () {
    if (heading && heading.lines) {
      gsap.fromTo(
        heading.lines,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.15,
          duration: 2,
          ease: "power4.out",
          delay: 1,
        }
      );
    }

    if (description && description.lines) {
      gsap.fromTo(
        description.lines,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.02,
          duration: 1.5,
          delay: 2,
          ease: "power4.out",
        }
      );
    }
    const landingSvg = document.querySelector(".landing svg");

    if (landingSvg) {
      gsap.fromTo(
        landingSvg,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          delay: 1.5,
        }
      );
    }

    const counterElement = $(".count");
    let counterExecuted = false;

    function isElementInViewport(el) {
      if (!el.length) return false;
      const rect = el[0].getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      );
    }

    function startCounter() {
      $(".counter-value").each(function () {
        const $this = $(this);
        const countTo = $this.attr("data-count");
        $({ countNum: $this.text().replace(/\D/g, "") }).animate(
          {
            countNum: countTo,
          },
          {
            duration: 3000,
            easing: "swing",
            step: function () {
              $this.text(Math.floor(this.countNum) + "+");
            },
            complete: function () {
              $this.text(this.countNum + "+");
            },
          }
        );
      });
    }

    const timelineItems = document.querySelectorAll(".timeline-item");

    timelineItems?.forEach(function (elem) {
      const elemLeft = elem.querySelector(".timeline-left");
      const elemRight = elem.querySelector(".timeline-right");

      function updateOpacityAndEvents() {
        const top = elem.getBoundingClientRect().top;
        const centerOfScreen = window.innerHeight / 2;
        const scrubValue = Math.max(
          0,
          Math.min(1, (centerOfScreen - top) / 150)
        );
        const opacity = 0.1 + scrubValue * 0.9; // Calculate opacity within the range [0.1, 1]
        elemLeft.style.opacity = opacity;
        elemRight.style.opacity = opacity;

        if (top <= centerOfScreen) {
          // Trigger your events here
          console.log("Top of element touched center of screen");
        }
      }

      window.addEventListener("scroll", updateOpacityAndEvents);
      updateOpacityAndEvents();
    });

    window.addEventListener("scroll", () => {
      if (isElementInViewport(counterElement) && !counterExecuted) {
        startCounter();
        counterExecuted = true;
      }
    });
  })();
  // Function to show "Read More" and "Read Less" text
  $(".read-more-button").on("click", function () {
    const $content = $(this).prev(".read-more-content");
    const $mores = $content.find(".more-text");
    const $moreText = $content.find("#more");

    if ($mores.css("display") === "none") {
      $mores.css("display", "inline");
      $moreText.css("display", "none");
      $(this).addClass("show-less").text("Read less");
    } else {
      $mores.css("display", "none");
      $moreText.css("display", "inline");
      $(this).removeClass("show-less").text("Read more");
    }
  });

  (function () {
    // Function to handle clicks on .bar (open/close mobile menu)
    function handleMobileMenuClick() {
      const mobileMenu = document.querySelector(".mobile-menu");
      const hamburgerMenu = document.querySelector(".hamburger-menu");

      if (mobileMenu.classList.contains("active")) {
        mobileMenu.classList.remove("active");
        hamburgerMenu.querySelector(".bar").classList.remove("animate");
      } else {
        mobileMenu.classList.add("active");
        hamburgerMenu.querySelector(".bar").classList.add("animate");
      }
    }

    // Event listener for opening/closing mobile menu when .bar is clicked
    document
      .querySelector(".hamburger-menu")
      .addEventListener("click", handleMobileMenuClick);

    // Event listener for closing mobile menu when clicked outside of it
    document.addEventListener("click", function (event) {
      const mobileMenu = document.querySelector(".mobile-menu");
      const hamburgerMenu = document.querySelector(".hamburger-menu");

      if (
        mobileMenu.classList.contains("active") &&
        !mobileMenu.contains(event.target) &&
        !hamburgerMenu.contains(event.target)
      ) {
        mobileMenu.classList.remove("active");
        hamburgerMenu.querySelector(".bar").classList.remove("animate");
      }
    });
  })();

  var pageTitle = $(".page-titles").text();

  // Get the input field for Contact Form 7
  var titlesInput = $("#titles");

  // Set the "value" attribute to the page title
  titlesInput.val(pageTitle);

  $(".book").on("click", function (e) {
    e.preventDefault(); // Prevent the link from redirecting
    var targetModal = $(this).data("target");
    $(targetModal).modal("show");
  });

});